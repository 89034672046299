$main-font-family: Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
    scrollbar-width: thin;
}

html {
    margin: 0;
    padding: 0;
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
    font-family: $main-font-family;
    font-size: 14px;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// classical application layouts VBox and HBox
// modelled with a CSS grid and auto flow
.layout-vbox, .layout-hbox, .toolbar {
    // note - this is *after* .fullsize-container to ensure you can
    // combine .fullsize-container.layout-vbox
    // (as we have to overrule the display "block")
    display: grid;

    > * {
        min-width: 0;
        min-height: 0;
        position: relative;
        // try to improve speed: 'size' is not an option as I found
        // grid layout issues to show up here and there
        // paint is not an option either - overlapping div.fieldset won't work
        // contain: layout /* size paint*/ style;
    }
}

.layout-vbox {
    grid-auto-flow: row dense;
    grid-template-rows: max-content;
}

.layout-hbox {
    grid-auto-flow: column dense;
    grid-template-columns: max-content;
}

.ol-control.my-zoom {
    display: grid;
    grid-template-rows: max-content;
    justify-content: left;
    padding: 10px;
    grid-gap: 2px;
    // position: relative;

    &.rotate-north {
        top: 70px;
    }
}

.info-popup {
    .label {
        font-weight: bold;
    }
}
